import {email} from "../constants/data";
import {FaEnvelope} from "react-icons/fa6";
import {motion} from "framer-motion";
import ContactCard from "../components/ContactCard";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

const contacts = [
    {
        title: "email",
        value: email,
        image: FaEnvelope,
    }
];

export default function Contact() {
    return (
        <>
            <div className="mt-16 mb-10">
                <motion.h2 className="text-6xl text-center text-text-accent"
                           initial={{opacity: 0}}
                           animate={{opacity: 1}}
                           transition={{duration: 0.5, ease: "easeInOut"}}
                >
                    Contact Us
                </motion.h2>
            </div>
            <div className="flex flex-row items-center justify-center text-text-secondary space-x-10">
                {
                    contacts.map((contact, index) => {
                        return (
                            <ContactCard {...contact} delay={index * 0.3} key={index}/>
                        );
                    })
                }
            </div>
        </>
    );
}
