import {useInView} from "react-intersection-observer";
import {motion} from "framer-motion";

const ContactText = ({title, value, FaIcon}) => {

    const copyValue = async (e) => {
        await navigator.clipboard.writeText(value);
        const target = e.target;
        target.textContent = "Copied!";
    }

    return (
        <div className="w-full px-3">
            <div className="flex items-start mb-1">
                <div className="flex items-center justify-start">
                    <h4 className="text-xl mr-4 ">Contact us by {title.toLowerCase()}</h4>
                </div>
                <div className="flex justify-center items-center">
                    <FaIcon size={25}/>
                </div>
            </div>
            <hr className="border border-text-primary"/>
            <div className="flex flex-col items-center my-2">
                <p className="text-lg mb-2">{value}</p>
                <motion.button onClick={(e) => copyValue(e)}
                               onMouseOut={(e) => setTimeout(() => e.target.textContent = "Copy", 150)}
                               className="bg-button-active rounded-full text-text-primary py-1 px-3 text-xl"
                               whileHover={{scale: 1.05}}
                               whileTap={{scale: 0.95}}
                >
                    Copy
                </motion.button>
            </div>
        </div>
    );
}

const ContactCard = ({title, value, image, delay = 0}) => {

    const {ref, inView} = useInView({triggerOnce: true});

    return (
        <motion.div ref={ref} className="my-8 border-2 border-text-accent shadow-purple-glow
            px-3 py-2 rounded-lg text-center"
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: inView ? 1 : 0, y: inView ? 0 : -50}}
                    transition={{delay: delay, duration: 0.5, ease: "backOut"}}
                    whileHover={{
                        scale: 1.05, y: -5,
                        transition: {duration: 0.3, ease: "easeInOut"}
                    }}
        >
            <ContactText title={title} value={value} FaIcon={image}/>
        </motion.div>
    );
}

export default ContactCard;