import {useEffect, useState} from "react";


export default function TypingAnimation({
                                            children: text = "",
                                            duration = 200,
                                            delay = 1000,
                                            className,
                                        }) {
    const [displayedText, setDisplayedText] = useState("_");
    const [ready, setReady] = useState(false);
    const [i, setI] = useState(1);

    setTimeout(() => {
        setReady(true);
    }, delay);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!ready) return
            setDisplayedText(text.slice(0, i));
            setI(i => i + 1);
        }, duration);

        return () => clearInterval(interval);
    }, [i, text, duration, ready]);

    return (
        <h1
            className={className}
        >
            {displayedText}
        </h1>
    );
}