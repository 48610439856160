import {useEffect, useState} from "react";
import clsx from 'clsx';
import {AnimatePresence, motion} from "framer-motion";
import TypingAnimation from "../components/Typewriter";
import {Link} from "react-router-dom";
import programming from "../images/programming.png";
import {statements, whyUs} from "../constants/data";
import InfoCard from "../components/InfoCard";
import {AiFillExclamationCircle} from "react-icons/ai";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

const missionStatements = ["think 🤔", "create 🔨", "innovate 🚀"];

export default function Hero() {
    const [statementIndex, setStatementIndex] = useState(0);
    const [initialAnimationDone, setInitialAnimationDone] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setStatementIndex((statementIndex + 1) % missionStatements.length);
        }, 4000);
        return () => clearInterval(interval);
    }, [statementIndex]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInitialAnimationDone(true);
        }, 1500); // Delay for the initial animation
        return () => clearTimeout(timer);
    }, []);

    return (<>
        <section>
            <div className="flex flex-col items-center justify-start w-full text-center min-h-screen">
                {/*banners*/}
                <div className="block justify-start w-full">
                    <button
                        className="flex flex-row items-center p-3 m-3 shadow-lg text-text-primary bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-3xl hover:shadow-xl space-x-3"
                        onClick={() => window.open("/classes")}
                    >
                        <AiFillExclamationCircle className="text-5xl text-text-primary"/>
                        <p>
                            Thank you for your interest in CodeUp! 2024 Fall Term Registrations have closed. Follow us on social media to stay up to date with our latest announcements and future terms! 🚀
                        </p>
                    </button>
                </div>

                <div className="flex flex-col items-center justify-center pt-12 lg:pt-20">
                    <motion.h1 animate={{
                        opacity: [0, 1], translateY: [-20, 10, 0], transition: {
                            duration: 0.75, ease: "easeInOut", loop: Infinity
                        }
                    }} className="mb-5 font-inter text-8xl text-text-primary">CodeUp
                    </motion.h1>
                    <motion.div animate={{
                        opacity: [0, 1], transition: {
                            duration: 0.75, ease: "easeInOut", loop: Infinity, delay: 0.6
                        }
                    }} className="flex flex-row mb-2">
                        <AnimatePresence
                            mode="wait">
                            <motion.p
                                key={statementIndex}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0, translateY: -25}}
                                transition={{duration: 0.5}}
                                className="p-2 text-2xl text-text-primary shadow-purple-glow rounded-md"
                            >
                                Empowering the next generation of youth
                                to {missionStatements[statementIndex].split(" ").map((word, index) => <span
                                key={index}
                                className={clsx("text-text-accent", index !== missionStatements[statementIndex].split(" ").length - 1 ? "underline" : "")}>
                                        {word}
                                    </span>)} <span className="font-bold">through code</span>
                            </motion.p>
                        </AnimatePresence>
                    </motion.div>
                    <motion.div className="relative mt-3" animate={{
                        opacity: [0, 1], transition: {
                            duration: 0.75, ease: "easeInOut", loop: Infinity, delay: 1.5
                        }
                    }}>
                        <TypingAnimation className="z-10 px-3 py-2 italic text-text-secondary" duration={100}
                                         delay={1500}>
                            Arrays of learning for youth
                        </TypingAnimation>
                        <motion.div
                            className="absolute -z-10 inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 backdrop-blur-lg opacity-50 rounded-3xl rotate-[-5deg]"
                            animate={{
                                width: ["0%", "100%"], transition: {
                                    duration: 2, ease: [0.87, 0.4, 0.5, 1], delay: 2
                                }
                            }}
                        >
                        </motion.div>
                    </motion.div>
                </div>
                <div className="p-5 mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                    {whyUs.map((entry, index) => {
                        return <motion.div
                            animate={{
                                scale: [0.4, 1], opacity: [0, 1], transition: {
                                    opacity: {
                                        duration: 1, ease: "easeInOut", delay: 1 + index * 0.25
                                    }, scale: {
                                        type: "spring", stiffness: 50, delay: 1 + index * 0.25
                                    }
                                }
                            }}
                        >
                            <InfoCard {...entry}/>
                        </motion.div>
                    })}
                </div>
                <Link className="flex-shrink-0" to="/classes">
                    <motion.button
                        whileTap={{scale: 0.95}}
                        whileHover={{scale: 1.05}}
                        initial={{scale: 0.8, opacity: 0}}
                        animate={{
                            opacity: 1, scale: 1, transition: {
                                duration: 1, type: "spring", stiffness: 100, delay: initialAnimationDone ? 0 : 2
                            }
                        }}
                        className="px-4 py-2 mt-5 font-bold rounded-full bg-button-active text-text-primary">
                        Browse Classes
                    </motion.button>
                </Link>
            </div>
        </section>
        <section className="flex flex-col p-10 text-text-primary m-7 space-y-20">
            <div className="mx-4 mb-5">
                <motion.h2 className="mb-6 text-6xl text-text-accent text-start"
                           initial={{opacity: 0}}
                           whileInView={{opacity: 1}}
                           viewport={{once: true}}
                           transition={{
                               duration: 0.5, ease: "easeInOut"
                           }}
                >Our Mission
                </motion.h2>
                <div
                    className="py-5 text-2xl text-text-primary md:text-4xl">
                    {statements.map((statement, index) => {
                        return (<motion.div
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            viewport={{once: true}}
                            transition={{duration: 0.5, delay: 0.5 + index * 0.5}}
                            key={index}
                        >
                            - {statement}
                        </motion.div>)
                    })}
                </div>
            </div>
        </section>
        <section className="m-20">
            <div>
                <motion.h2 className="mb-5 text-4xl text-center text-text-accent"
                           initial={{opacity: 0}}
                           whileInView={{opacity: 1}}
                           viewport={{once: true}}
                           transition={{duration: 0.5, ease: "easeInOut"}}
                >What We Do
                </motion.h2>
                <motion.p className="my-3 text-2xl text-center text-text-secondary md:text-3xl"
                          initial={{opacity: 0}}
                          whileInView={{opacity: 1}}
                          viewport={{once: true}}
                          transition={{duration: 0.5, ease: "easeInOut"}}
                >

                    CodeUp is a registered not-for-profit corporation that strives to educate youth in computer science and
                    develop code literacy at a young age. Our
                    lessons are currently offered free of charge, and aim to curate an interest in computer science and
                    software development and compel youth
                    to explore potential career opportunities. Profits are re-invested into the community to further
                    our mission of empowering the next generation.
                </motion.p>
            </div>
        </section>
        <motion.section className="flex flex-row items-center justify-center m-20 text-center" initial={{opacity: 0}}
                        whileInView={{
                            opacity: 1
                        }}
                        viewport={{once: true}}
                        transition={{duration: 0.5, ease: "easeInOut"}}>
            <div>
                <h1 className="text-4xl text-text-primary">
                    Why Learn to Code?
                </h1>
                <p className="max-w-5xl mt-5 text-3xl text-text-secondary md:text-2xl">
                    Coding is a valuable skill that can be applied to a wide range of fields. It teaches
                    problem-solving,
                    critical thinking, and creativity. Learning to code will also open up a world of opportunities and
                    possibilities for the future. Especially in today's digital age, coding is an essential skill that
                    will
                    set up he next generation for success. Even sectors that are not traditionally associated with
                    coding
                    such as
                    healthcare, finance, and education are now incorporating software into their operations. It is great
                    to
                    start learning to code at a young age as it helps youth develop a strong foundation in computer
                    science
                    and prepare them for future success.
                </p>
            </div>
            <img className="w-0 h-0 lg:w-1/2 lg:h-1/2" src={programming} alt="Coding"/>
        </motion.section>
        {/*    todo: testimonials later */}
    </>);
}
