import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

export default function TOS() {
    return <div>
        <p className="text-text-primary text-lg p-20">
            <span className="text-2xl">
                Liability Waiver:
            </span>
            <br/>
            I hereby declare that, in the unlikely event of an injury occurring during the course of the classes,
            CodeUp, its volunteers, instructors, and any associated entities shall be exempt from any and all liability,
            claims, or causes of action arising out of or related to such an injury.
            <br/>
            <br/>
            <span className="text-2xl">
                Terms of Participation:
            </span>
            <br/>
            By registering for this course, I understand that my child will be required to bring their own laptop for
            use during the classes and agree that they will follow all rules and regulations of the Richmond HIll Public
            Library while attending in-person sessions. I also understand that CodeUp is not responsible for any damage
            or loss of personal property. By participating in this course, I also acknowledge that pictures and videos
            including my child may be taken for promotional purposes.
            <br/>
            <br/>
            <span className="text-2xl">
                Privacy Policy
            </span>
            <br/>
            CodeUp heavily values your privacy. Information provided to us will only be used for course
            registration, communication, and internal reporting purposes. We will not disclose or sell any of this information to
            any third parties.
        </p>
    </div>
}
