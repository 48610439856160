import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getApps, getApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBm7ZhwSd-oA81RUZDGiMNaXGvAvHY8mL4",
    authDomain: "codeup-tutoring.firebaseapp.com",
    projectId: "codeup-tutoring",
    storageBucket: "codeup-tutoring.appspot.com",
    messagingSenderId: "260146768052",
    appId: "1:260146768052:web:589937a3f8d210f3ae8fe1",
    measurementId: "G-7XJGLLGC0B"
};

function getFirebase() {
    if (getApps().length === 0) {
        return initializeApp(firebaseConfig);
    } else {
        return getApp();
    }
}

export const app = getFirebase();
export const analytics = getAnalytics(app);