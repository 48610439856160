import LocationCard from "../components/LocationCard";
import {motion} from "framer-motion";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

const locations = [
    {
        location: "Richmond Hill Public Library (Richmond Green Branch)",
        mapEmbed: <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d862.9312482709247!2d-79.39786284790185!3d43.90277143458204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2aa134508d71%3A0xeddf1f72f246edb6!2sRichmond%20Green%20Branch%20(Richmond%20Hill%20Public%20Library)!5e0!3m2!1sen!2sca!4v1723736984832!5m2!1sen!2sca"
            width="300" height="300" style={{border: 0}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>,
        mapLink: "https://maps.app.goo.gl/YqEtMBCaRMZziHCF6"
    }
];

export default function Locations() {
    return (
        <>
            <div className="mt-16 mb-10">
                <motion.h2 className="text-6xl text-center text-text-accent"
                           initial={{opacity: 0}}
                           animate={{opacity: 1}}
                           transition={{duration: 0.5, ease: "easeInOut"}}
                >
                    Locations
                </motion.h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center gap-y-5">
                {
                    locations.map((location, index) => {
                        return (
                            <LocationCard {...location} key={index} delay={(index * 0.15) + 0.25}/>
                        );
                    })
                }
            </div>
        </>
    );
}
