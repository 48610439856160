import {FiExternalLink} from "react-icons/fi";

export default function HeadshotCard({name, roles, description, image, portfolio}) {
    return <div className="flex flex-wrap flex-col items-center h-full bg-content-bg bg-opacity-60 p-5 rounded-lg">
        <div className="relative w-full flex items-center justify-center">
            <h2 className="text-text-primary text-3xl mb-3">{name}</h2>
            {
                portfolio &&
                <a className="text-text-accent text-2xl absolute right-0 top-0" href={portfolio} target="_blank"
                   rel="noreferrer">
                    <FiExternalLink/>
                </a>
            }
        </div>
        <p className="text-text-accent text-lg mb-3 italic text-center">{roles.join(" | ")}</p>
        <img src={image} alt={name} className="rounded-full max-h-64 max-w-64"/>
        <p className="text-text-secondary text-center mt-5">{description}</p>
    </div>
}