import {motion} from 'framer-motion';
import {people} from "../constants/data";
import HeadshotCard from "../components/HeadshotCard";
import {useInView} from "react-intersection-observer";
import {analytics} from "../constants/firebase";
import {logEvent} from "firebase/analytics";

export default function About() {
    const {ref: peopleRef, inView: peopleInView} = useInView({triggerOnce: true});

    return (// TODO: do gradient later with whole body
        <div className="flex flex-col flex-wrap space-y-10">
            <section ref={peopleRef}>
                <div className="mt-5">
                    <motion.h3 className="text-4xl my-5 text-text-primary text-center"
                               initial={{opacity: 0}}
                               animate={{opacity: peopleInView ? 1 : 0}}
                               transition={{duration: 0.5, delay: 0.8}}
                    >Meet the Team
                    </motion.h3>
                    <motion.div className="grid grid-cols-1 md:grid-cols-3 place-items-center my-5 p-8 gap-10"
                                animate={{
                                    opacity: peopleInView ? [0, 1] : 0, transition: {
                                        duration: 0.3, ease: "easeInOut", delay: 0.2
                                    }
                                }}
                                initial={{opacity: 0}}
                    >
                        {people.map((person, index) => {
                            return (<motion.div
                                key={index}
                                className="hover:shadow-purple-glow my-4 h-full"
                                whileTap={{scale: 0.98}}
                                whileHover={{scale: 1.02}}
                                initial={{
                                    translateY: -100,
                                }}
                                animate={{
                                    translateY: peopleInView ? 0 : -100
                                }}
                                transition={{
                                    translateY: {
                                        duration: 0.3,
                                        ease: "easeInOut",
                                        delay: peopleInView ? 0.3 + index * 0.15 : 0,
                                    }
                                }}
                            >
                                <HeadshotCard {...person} />
                            </motion.div>);
                        })}
                    </motion.div>
                </div>
            </section>
        </div>);
}
