import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function LocationCard({ location, mapEmbed, mapLink, schedule, delay = 0 }) {

    const { ref, inView } = useInView({ triggerOnce: true });

    const redirect = () => {
        if (mapLink !== null && mapLink !== undefined && mapLink !== "") {
            window.open(mapLink, "_blank", "noopener,noreferrer");
        }
    }

    return (
        <motion.div ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: delay, duration: 0.5, ease: "easeInOut" }}
            className="p-10 bg-content-bg text-white flex flex-col items-center bg-opacity-60 text-center"
            onClick={redirect}
            whileHover={{ scale: 1.025, y: -5,
                transition: {duration: 0.3, ease: "easeInOut"}
            }}
            whileTap={{ scale: 0.975, 
                transition: {duration: 0.3, ease: "easeInOut"}
            }}
        >
            <h3 className="text-2xl my-3">{location}</h3>
            <hr className="border border-white mb-3 w-full" />
            <p className="text-xl mb-3">{schedule}</p>

            <div className="max-w-[350px] lg:max-w-[500px]">
                {mapEmbed}
            </div>

            <motion.button onClick={redirect}
                className="bg-button-active rounded-full text-text-primary py-1 px-3 mt-3 text-xl"
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.975 }}
            >
                View on Google Maps
            </motion.button>
        </motion.div>
    );
}
