import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Hero from './pages/Hero';
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Classes from "./pages/Classes";
import Locations from "./pages/Locations";
import Contact from "./pages/Contact";
import TOS from "./pages/TOS";

export default function App() {
    return (
        // multi-page boilerplate
        <BrowserRouter>
            {/* wrap all pages with a default layout */}
            <Layout>
                <Routes>
                    <Route index element={<Hero/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="classes" element={<Classes/>}/>
                    <Route path="locations" element={<Locations/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="tos" element={<TOS/>}/>

                    {/* default, catch-all route */}
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}
