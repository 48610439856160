import {Link, useLocation} from "react-router-dom";
import codeup from "../images/codeup.png";
import {FaDiscord, FaInstagram} from "react-icons/fa";
import {AiFillWechat} from "react-icons/ai";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";
import {useEffect, useRef} from "react";

const links = {
    "Home": "/",
    "About": "/about",
    "Classes": "/classes",
    "Locations": "/locations",
    "Contact": "/contact"
}

export default function Layout({children}) {
    const location = useLocation();
    const previousLocation = useRef(null);

    useEffect(() => {
        // This will run whenever the route changes
        if (previousLocation.current && previousLocation.current.pathname === location.pathname) {
            return console.log("Same location");
        }
        if (Object.values(links).find(link => link === location.pathname)) {
            logEvent(analytics, "page_view");
        }
        previousLocation.current = location;
    }, [location]);

    return (
        <>
            <header className="relative mb-5 z-10">
                {/*todo: hamburger menu for mobile */}
                <nav className="grid grid-cols-1 md:grid-cols-[1fr,2fr] p-2">
                    <div className="flex flex-row flex-wrap items-center space-x-2">
                        <img src={codeup} alt="Codeup Logo" className="col-span-1 h-16 w-16 rounded-full"/>
                        <h3 className="text-text-primary text-2xl">CodeUp</h3>
                        <a href="https://discord.gg/6mpBtqPQDM" target="_blank" rel="noreferrer" onClick={() => {
                            logEvent(analytics, "discord_link_clicked");
                        }}
                           className="flex items-center justify-center p-3 mx-2 text-3xl rounded-xl transition ease-in-out duration-200 text-text-primary
                                 hover:bg-button-active hover:-translate-y-1 hover:scale-105">
                            <FaDiscord/>
                        </a>
                        <a href="/wechat.jpg" target="_blank" rel="noreferrer" onClick={() => {
                            logEvent(analytics, "wechat_link_clicked");
                        }}
                           className="flex items-center justify-center p-3 mx-2 text-3xl rounded-xl transition ease-in-out duration-200 text-text-primary
                                 hover:bg-button-active hover:-translate-y-1 hover:scale-105">
                            <AiFillWechat/>
                        </a>
                        <a href="https://www.instagram.com/codeup.ca/" target="_blank" rel="noreferrer"
                           onClick={() => {
                               logEvent(analytics, "instagram_link_clicked");
                           }}
                           className="flex items-center justify-center p-3 mx-2 text-3xl rounded-xl transition ease-in-out duration-200 text-text-primary
                                 hover:bg-button-active hover:-translate-y-1 hover:scale-105">
                            <FaInstagram/>
                        </a>
                    </div>
                    <div className="flex flex-row flex-wrap col-span-1">
                        {
                            Object.keys(links).map(key => {
                                return (
                                    <Link key={key} to={links[key]}
                                          className="flex items-center justify-center p-3 mx-2 text-xl rounded-xl transition ease-in-out duration-200 text-text-primary
                                 hover:bg-button-active hover:-translate-y-1 hover:scale-105">
                                        {key}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </nav>
                <div className="absolute inset-0 bg-navbar-bg opacity-30 backdrop-blur-xl -z-10"/>
            </header>

            <main>{children}</main>

            <footer></footer>
        </>
    );
}
