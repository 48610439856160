import alexLu from "../images/alex_lu_headshot.jpg";
import oscar from "../images/oscar_headshot.png";
import oscarCheng from "../images/oscar_c_headshot.jpg";
import anderson from '../images/anderson_headshot.jpg';
import matthew from '../images/matthew_headshot.png';
import lucas from '../images/lucas_headshot.png';
import leo from '../images/leo_headshot.png';
import python from "../images/python.png";
import {Programme} from "../components/Programme";
import {FaPeopleGroup} from "react-icons/fa6";
import {FaBookOpen, FaChalkboardTeacher} from "react-icons/fa";
import {GiTinker} from "react-icons/gi";
import jerry from "../images/jerry.jpg";
import sonia from "../images/sonia_headshot.png"

export const people = [
    {
        name: "Oscar He",
        roles: ["Founder", "Chief Executive Officer", "Fullstack Developer", "Instructor"],
        description: "Oscar He is a co-founder and the CEO of CodeUp. He is a student in the class of '26 at St. Robert CHS. Oscar is very passionate about fullstack development and machine learning, and also has a passion for teaching. He has five years of experience in software development and has worked on various projects which span a variety of sectors including web development, backends, desktop applications, and machine learning models. He also has two years of experience with competitive programming, achieving a perfect score on his first CCC Junior in 2022. In his free time, he likes to code, study mathematics, and play badminton. Oscar is very dedicated to helping students learn and grow, and hopes to inspire youth to innovate through programming",
        image: oscar,
        portfolio: "https://oscar-he.vercel.app/"
    },
    {
        name: "Anderson Lai",
        roles: ["Founder", "Chief Technology Officer", "Fullstack Developer", "Instructor"],
        description: "Anderson Lai is a co-founder and the CTO of CodeUp. He is most interested in systems-level programming and full-stack web development, but also does desktop app development. He has worked at GrtHealth and at the Canadian Heart Research Centre doing full-stack web development with .Net Core, MySQL, and Javascript. In his free time, he has worked on various performance-intensive projects, and is currently developing a desktop application using C++. Outside of programming, Anderson plays badminton weekly and plays accompaniament on the piano bi-weekly at church. Anderson aims to help students grow in their technical skills whilst fostering individual growth",
        image: anderson,
        portfolio: "https://andersonlai.vercel.app/"
    },
    {
        name: "Matthew Hou",
        description: "Matthew Hou is an instructor and a curriculum coordinator for CodeUp. He is passionate about competitive programming, and is dedicated to ensuring a meaningful learning experience for all programmers. Matthew has experience working with children through his volunteering work at community centers and libraries. He is actively working on a GitHub repository with coding solutions in various languages and learning web development with the goal of developing a website that explains algorithms and problem solutions. Outside of coding, Matthew enjoys solving math problems, attending debate tournaments, and swimming. Matthew is dedicated to making coding interesting for students and helping them develop important skills.",
        roles: ["Curriculum Coordinator Lead", "Instructor"],
        image: matthew
    },
    {
        name: "Jerry Wang",
        description: "Jerry Wang is an instructor and a curriculum coordinator for CodeUp. He is passionate about programming and works hard to improve his skills in coding at a competitive level. He has many hours of experience teaching students subjects like French, English and Math and works well with children passionate about learning. With many years of experience with Python, Jerry is a great teacher for students who are struggling to learn their first coding language. He is active in many competitive fields such as Math, Coding and Lifesaving (Swimming). Through CodeUp, Jerry hopes many children will be more interested in programming and develop a passion for coding like he has.",
        roles: ["Curriculum Coordinator", "Instructor"],
        image: jerry
    },
    {
        name: "Oscar Cheng",
        description: "Oscar Cheng is the outreach lead of the CodeUp organization. He is a graphic artist who enjoys coding and drawing his elements. He has worked with Turtle Programming in Python to develop new elements that introduce computer science to the arts. Oscar loves to share his passion for art in his free time through singing and photography. He is passionate about emphasizing the \"A\" in STEAM and helping students to think creatively in their daily lives and overcome the challenges faced in their respective interests. Oscar looks forward to assisting students to achieve success by increasing the accessibility to learning skills such as coding and software development.",
        roles: ["Outreach Lead"],
        image: oscarCheng
    },
    {
        name: "Alex Lu",
        description: "Alex Lu is a curriculum coordinator for CodeUp. He is interested in automation and software development. Alex enjoys problem-solving and automation and spends his free time coding and exploring new technologies. Outside of programming, Alex enjoys playing sports such as badminton. Alex is dedicated to helping students entering high school develop their software development skills.",
        roles: ["Curriculum Coordinator", "Outreach"],
        image: alexLu
    },
    {
        name: "Lucas Guan",
        description: "Lucas Guan is a member of the CodeUp outreach team. He has a strong interest in math and science and explores the subjects both by himself and by teaching others. He often teaches students math, science, badminton and piano. In addition to academics, he also finds joy in playing piano and exploring new songs. Lucas is passionate about promoting CodeUp, allowing access to coding education to all students, and is committed to promoting CodeUp's mission.",
        roles: ["Outreach"],
        image: lucas
    },
    {
        name: "Leo Yang",
        description: "Leo Yang is the finance lead and a outreach coordinator for CodeUp. He is passionate about Python and enjoys learning about web development and computer hardware, occasionally researching computer technologies as well as new programming languages. Leo has experience teaching and developing children through working as a snowboard instructor, swimming assistant, and umpire. In his free time, he enjoys running, practicing the piano, or playing golf. Leo is determined to strengthen CodeUp through it's financial foundation, fueled by his fascination in the field of financial mathematics. Ultimately, Leo is excited to be apart of CodeUp's journey, and is determined to improve students' coding skills.",
        roles: ["Finance Lead", "Outreach"],
        image: leo
    },
    {
        name: "Sonia Olar",
        description: "Sonia Olar is an instructor for CodeUp. She is passionate about programming and has experience with web development, competitive programming, and robotics. She has worked at Principle Inc. where she used database programming for back-end development as well as built user interfaces using Java and Javascript. Sonia also loves working with kids and is dedicated to creating a fun learning environment! She has taught reading, writing, and robotics classes, as well as coached volleyball to kids and teens alike. For fun, she loves drawing, listening to music, and playing volleyball herself! Sonia hopes to inspire kids to pursue coding with the CodeUp team!",
        roles: ["Instructor"],
        image: sonia
    }
    // {
    //     name: "Ian Chen",
    //     roles: ["Instructor"],
    // },
    // {
    //     name: "Aidan Lau",
    //     roles: ["Finance"],

    // },
    // {
    //     name: "Kevin Yang",
    //     roles: ["Curriculum Coordinator"],

    // },
    // {
    //     name: "Ethan xu",
    //     roles: ["Instructor"],
    // }
]

export const statements = [
    "To empower youth to innovate through programming",
    "To train the next generation of problem solvers",
    "To create opportunities for growth and continuous learning",
    "To make programming accessible to all students"
]

export const classes = [
    <Programme name="Intro to Coding"
               description="A comprehensive beginner's course for the Python programming language spanning 12 engaging classes. Covers fundamental and intermediate concepts to kickstart your child's software development journey. Students will become proficient in coding through building a game using the Pygame library."
               image={python} modalInfo={
        <div className="flex flex-col text-text-primary space-y-2">
            <p>This course will cover the ins and outs of programming in Python. More importantly, students will learn
                how to <span className="underline">think</span> like a software developer, teaching them how to approach
                and overcome all sorts of challenges in programming.</p>
            <p>Students will learn all of these programming concepts through developing a game using the Pygame
                library</p>
            <p>The curriculum for this course covers the following:</p>
            <ul className="list-disc list-inside">
                <li>Variables and primitive data types</li>
                <li>Built-in libraries such as <code>random</code> and <code>math</code></li>
                <li>Conditionals and booleans</li>
                <li>Loops and iteration</li>
                <li>Filesystem and JSON</li>
                <li>Non-primitive data types such as arraylists and hash maps</li>
                <li>How to use documentation and other resources to solve problems</li>
                <li>Classes and basic OOP</li>
                <li>External libraries such as Pygame</li>
            </ul>
            <p>In-person lessons will take place once a week for three months, totaling twelve lessons</p>
        </div>
    }/>
]

export const whyUs = [
    {
        title: "All Ages and Skill Levels",
        description: "Our courses are designed to be accessible to all ages and skill levels. Whether you are a beginner or have experience in programming, we have a course for you",
        Icon: FaPeopleGroup
    },
    {
        title: "Experienced Instructors",
        description: "Our instructors have a lot of experience in programming and are dedicated to helping students learn and grow",
        Icon: FaChalkboardTeacher
    },
    {
        title: "Comprehensive Curriculum",
        description: "Our curriculum is designed to not only give students a comprehensive understanding of the subject matter but also to develop problem solving skills to solve arbitrary problems",
        Icon: FaBookOpen
    },
    {
        title: "Hands-On Learning",
        description: "We believe that the best way to learn is by doing. Our lessons are designed to provide an interactive and engaging hands-on experience for students",
        Icon: GiTinker
    }
]

export const email = "support@codeup.ca";
