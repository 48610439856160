import {Link} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

export default function NotFound() {
    return (
        <div className="flex flex-col items-center justify-center h-[80vh]">
            <h1 className="text-3xl text-text-primary">Whoops! This page doesn't seem to exist!</h1>
            <Link to={"/"} className="text-text-accent underline mt-2">Go back home</Link>
        </div>
    );
}
