import {createContext, useContext, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {RxCross1} from "react-icons/rx";
import {analytics} from "../constants/firebase";
import {logEvent} from "firebase/analytics";

const ProgrammeContext = createContext(null);

function ContextProvider({children}) {
    const [selected, setSelected] = useState(false);

    return (
        <ProgrammeContext.Provider value={{selected, setSelected}}>
            {children}
        </ProgrammeContext.Provider>
    );
}

export function Programme({name, description, image, modalInfo}) {
    return (
        <ContextProvider>
            <Internal name={name} description={description} image={image}/>
            <ProgrammeModal name={name} modalInfo={modalInfo}/>
        </ContextProvider>
    );
}

function Internal({name, description, image}) {
    const {selected, setSelected} = useContext(ProgrammeContext);

    return (
        <div className="flex flex-col items-center justify-center bg-content-bg bg-opacity-80 rounded-lg p-5">
            <h1 className="text-3xl text-center text-text-primary">{name}</h1>
            <img src={image} alt={name} className="max-w-64 max-h-64 rounded-full overflow-hidden"/>
            <p className="text-text-secondary text-center">{description}</p>
            <div className="flex flex-row space-x-8">
                <motion.button
                    onClick={() => {
                        setSelected(!selected)
                        logEvent(analytics, "python_info_clicked");
                    }}
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                    className="bg-button-active text-sm text-text-primary p-3 mt-2 rounded-3xl"
                >
                    More Info
                </motion.button>
                <motion.button
                    onClick={() => {
                        // window.open("https://forms.gle/ZrAkwCTvFLfyRCPQ6", "_blank")
                        // logEvent(analytics, "python_register_clicked");
                    }}
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                    className="bg-button-inactive text-sm text-text-primary p-3 mt-2 rounded-3xl cursor-not-allowed"
                disabled>
                    Register
                </motion.button>
            </div>
        </div>
    );
}

function ProgrammeModal({name, modalInfo}) {
    const {selected, setSelected} = useContext(ProgrammeContext);

    return (
        <AnimatePresence>
            {selected && (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="fixed inset-0 bg-black backdrop-blur-md bg-opacity-40 flex items-center justify-center"
                >
                    <motion.div
                        initial={{y: -100}}
                        animate={{y: 0}}
                        exit={{y: -100}}
                        className="relative bg-content-bg p-8 rounded-lg w-2/3 h-2/3 flex flex-col items-center overflow-y-scroll"
                    >
                        <motion.button onClick={() => setSelected(false)}
                                       whileHover={{scale: 1.05}}
                                       whileTap={{scale: 0.95}}
                                       className="absolute top-5 right-5 text-button-active text-4xl">
                            <RxCross1/>
                        </motion.button>
                        <p className="text-text-secondary text-3xl mb-5">{name}</p>
                        {modalInfo}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
