import {motion} from "framer-motion";
import {classes} from "../constants/data";
import {logEvent} from "firebase/analytics";
import {analytics} from "../constants/firebase";

export default function Classes() {
    return (<div>
        <motion.h2 className="text-6xl text-center text-text-accent mt-16 mb-6"
                   animate={{
                       opacity: [0, 1], transition: {
                           duration: 0.5, ease: "easeInOut"
                       }
                   }}
        >Classes
        </motion.h2>
        <p className="text-text-primary text-xl text-center mx-8">
            All classes here are taught by our experienced instructors and are currently offered <span className="underline">free of charge</span>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 my-5 mx-8 p-8">
            {classes.map((programme, index) => (<motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{opacity: {duration: 0.75, delay: 0.25 + 0.1 * index}}}
                key={index}
            >
                {programme}
            </motion.div>))}
        </div>
    </div>);
}
